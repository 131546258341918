<template>
  <!-- 设置银行卡 支付宝页面 -->
  <div class="withDrawPage">
    <topbar title="提现设置"> </topbar>
    <van-tabs v-model="active" @change="getCardTypeList">
      <van-tab title="银行卡" name="bank">
        <div class="content">
          <template v-for="(card, index) in cardTypeList">
            <van-swipe-cell :key="'card' + index">
              <template #right>
                <div class="rightBtn">
                  <van-button type="danger" size="small" @click="deleteCard(card.id)">
                    删除
                  </van-button>
                </div>
              </template>
              <BankCard :data="card"></BankCard>
              <div style="margin-bottom: 10px"></div>
            </van-swipe-cell>
          </template>
        </div>
      </van-tab>
      <van-tab title="支付宝" name="alipay">
        <div class="content">
          <template v-for="(card, index) in cardTypeList">
            <van-swipe-cell :key="'card' + index">
              <template #right>
                <div class="rightBtn">
                  <van-button type="danger" size="small" @click="deleteCard(card.id)">
                    删除
                  </van-button>
                </div>
              </template>
              <BankCard :data="card"></BankCard>
              <div style="margin-bottom: 10px"></div>
            </van-swipe-cell>
          </template>
        </div>
      </van-tab>
    </van-tabs>
    <div class="addCard">
      <template v-if="active == 'bank'">
        <div>添加银行卡·安全 便捷 极速</div>
        <div>当前仅支持对私银行卡</div>
        <van-button block type="danger" @click="addBankPage('bank')">添加银行卡</van-button>
      </template>
      <template v-if="active == 'alipay'">
        <div>绑定支付宝账号·便捷</div>
        <div>便捷生活，一点就好</div>
        <van-button block type="danger" @click="addBankPage('alipay')">添加支付宝</van-button>
      </template>
    </div>
  </div>
</template>
<script>
import topbar from '@/component/topbar.vue'
import BankCard from './__com__/card.vue'

export default {
  components: { topbar, BankCard },
  name: '',
  data() {
    return {
      active: 'bank',
      cardTypeList: []
    }
  },
  created() {
    let type = this.$route.query.type
    if (type) this.active = type
  },
  mounted() {
    this.getCardTypeList()
  },
  methods: {
    addBankPage(type) {
      this.$router.push('/setting/addBankCard?type=' + type)
    },
    deleteCard(id) {
      this.$dialog
        .confirm({
          title: `移除${this.active == 'bank' ? '银行卡' : '支付宝'}账号`
        })
        .then(() => {
          this.$http
            .delete(`/boom-center-user-service/app/distribution/member/account/${id}`)
            .then(res => {
              this.$notify({
                type: 'success',
                message: '删除成功'
              })
              this.getCardTypeList()
            })
            .catch(err => {
              this.$notify({
                type: 'warning',
                message: err.msg
              })
            })
            .finally(() => {})
        })
        .catch(() => {
          this.$notify({
            type: 'success',
            message: '取消'
          })
        })
    },
    // 查询分销员收款账号列表
    getCardTypeList() {
      let accountType = this.active == 'bank' ? 1 : this.active == 'alipay' ? 2 : 3
      this.$http
        .get(`/boom-center-user-service/app/distribution/member/account/list`, {
          params: {
            // id: 0,
            accountType
          }
        })
        .then(res => {
          this.cardTypeList = res
        })
        .catch(err => {
          this.$notify(err.msg)
        })
        .finally(() => {})
    }
  }
}
</script>
<style lang='less' scoped>
.withDrawPage {
  background-color: #f5f5f5;
  height: 100vh;
  .content {
    padding: 20px 15px 0;
    .rightBtn {
      height: 100%;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .addCard {
    background-color: #fff;
    margin: 0 15px;
    padding: 24px 20px 30px;
    margin-top: 20px;
    text-align: center;
    border-radius: 6px;
    & > div {
      &:nth-child(1) {
        height: 28px;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 23px;
      }
      &:nth-child(2) {
        margin-top: 6px;
        margin-bottom: 45px;
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }
    }
  }
}
</style>